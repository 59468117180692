html,
body {
    position: relative;
    min-width: 100%;
    min-height: 100%;
    overflow-x: hidden;
}

body {
    padding-bottom: 2em;
    margin: 0 0 4.5em 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    padding-bottom: 2em;
    overflow: hidden;
}

/* universal shared classes */
.feedback-btn {
    position: fixed !important;
    bottom: 1em !important;
    right: 1em !important;
}

.feedback-btn .MuiFab-label > svg {
    margin-right: 5px;
}

.container {
    max-width: 450px;
}

.title {
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
}

.form > div {
    margin-right: 1em;
}

.center {
    margin: 0 auto;
}

.hide {
    display: none;
}

.inline {
    display: inline;
}

.red {
    color: red;
}
