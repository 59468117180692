.login-container {
    display: flex;
    justify-content: center;
    margin-top: 15%;
}

.login-paper-container {
    border-radius: 8px;
    position: relative;
    left: auto;
    right: auto;
    padding: 2em;
}

.left-side {
    max-width: 50%;
    padding: 1em 2em 1em 1em;
}

.social-login-border-right {
    border-left: 1px solid lightgray;
    padding-left: 2em;
    padding: 1em 1em 1em 2em;
    text-align: center;
}

.buttons {
    margin: 2em 0 0 1em;
}

.register-button {
    margin-left: auto;
    text-decoration: none;
}

.sign-in-header {
    background: #fff;
    margin-top: -80px;
    position: absolute;
    padding: 20px;
}

.social-login-space {
    margin-top: 20px;
}

.login-content-container {
    margin-top: 10px;
}

.manual-input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 2em 0 1em 0;
}

.manual-input-container > div {
    margin-top: 1em;
}

.reset-password-container {
    min-width: 10em;
    margin-top: 1em;
}

@media screen and (max-width: 654px) {
    .left-side {
        max-width: 100%;
        width: 100%;
        padding: 0;
    }
    
    .social-login-border-right {
        width: 100%;
        margin: 0 auto;
        padding: 1em 0 0;
        border-left: none;
    }
}
