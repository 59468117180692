button > span{
    border-radius: 5px !important;
}

button > span img {
    width: 300px;
}

.name-container {
    padding: 1em;
}

.comparison-container {
    width: calc(100% - 32px);
    padding: 2em;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
}

.undo-container {
    padding-top: 2.5em !important;
}

.button-container {
    margin-top: 1em;
}

img[src=""] {
    display: none;
}

span > p {
    margin: 0;
}