.my-lists-container {
    margin-top: 2em;
    max-width: 450px;
    width: 100%;
}

@media screen and (min-width: 520px) {
    .my-lists-container {
        width: calc(100% - 50px);
    }
}
