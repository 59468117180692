.buttons {
    margin: 2em 0 0 1em;
}

.form {
    padding-left: 1em;
}

.title {
    margin-top: 2em !important;
    margin-bottom: 1em !important;
}