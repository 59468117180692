.result-title {
    margin: 2em auto 0 auto !important;
}

.sub-title {
    margin: 1em auto !important;
    text-align: center;
    font-style: italic;
    color: #777;
}

.table-container {
    width: 100%;
}

.table-row img {
    max-width: 200px;
    max-height: 300px;
}

.table-index {
    font-size: 1.5em !important;
    font-weight: bold !important;
}

.share-button {
    position: relative;
    top: 6px;
}
