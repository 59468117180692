.register-paper-container {
    padding: 2em;
    left: auto;
    position: relative;
    right: auto;
}

.input-container {
    padding-top: 1em;
}

.input-container > div {
    margin-right: 1em;
}

.register-button-container {
    margin-top: 20px;
}
